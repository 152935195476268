import React, { FC, useState } from 'react';

import { IFAQ } from '../../assets/interfaces/faqInterface/index';
import FAQItem from '../faqItem/FAQItem';

import styles from './FAQList.module.scss';

type TFAQList = {
    faqList: IFAQ[];
};

const FAQList: FC<TFAQList> = ({ faqList }) => {
    return (
        <div className={styles.faq__wrapper}>
            <ul className={styles.faq__list}>
                {faqList.map((item, i) => {
                    return <FAQItem key={`${item._id}__${i}`} item={item} index={i} />;
                })}
            </ul>
        </div>
    );
};

export default FAQList;
